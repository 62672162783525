import { Field } from 'react-final-form';
import type { FieldRenderProps } from 'react-final-form';
import styled from 'styled-components';
import { useState } from 'react';

import { Select } from 'components/Toolkit/Inputs/Select';
import { TextField } from 'components/Toolkit/Inputs/TextField';
import { SubText } from 'components/Toolkit/Inputs/SubText';
import { Label } from 'components/Toolkit/Inputs/Label';

import { handleErrorMessage } from 'helpers/forms';
import { ErrorMessage } from 'components/Toolkit/Inputs/ErrorMessage';
import { HelpText } from 'components/Toolkit/Inputs/HelpText';
import { required } from 'helpers/InputValidators';
import type { AutoComplete } from 'components/Toolkit/Inputs/types';

interface ISSelect {
  flip: boolean;
  isWideUnit: boolean;
}

interface StrictOption {
  value: string;
  label: string;
  disabled?: boolean;
}

type SelectOption = string | StrictOption;

export interface ITextBoxWithUnit {
  unitName?: string;
  valueName?: string;
  options: SelectOption[];
  unitErrorMessage?: string | null;
  valueErrorMessage?: string | null;
  placeholder?: string;
  initialUnitValue?: string;
  maxLength?: number;
  flip?: boolean;
  formatFunction?: (t: string | number) => string | number | undefined;
  parseFunction?: (t: string | number) => string | number | undefined;
  handleChangeCallback?: (t?: string) => void;
  handleBlurCallback?: (t?: string) => void;
  handleFocusCallback?: (t?: string) => void;
  feedbackNotification?: boolean;
  willUseSubText?: boolean;
  autoComplete?: AutoComplete;
  className?: string;
  displayName?: string;
  minimizeByDefault?: boolean;
  selectDisabled?: boolean;
  // Provides extra space for longer text options e.g. 'miles'
  isWideUnit?: boolean;
  optional?: boolean;
  inputPattern?: string;
  helpMessage?: string;
}

export const Container = styled.div<{ willUseSubText: boolean }>`
  display: flex;
  height: 40px;
  ${({ theme, willUseSubText }) =>
    willUseSubText ? `margin-bottom: ${theme.spacing.S4}` : ''};
`;

export const SSelect = styled(Select)<ISSelect>`
  width: ${({ theme, isWideUnit }) =>
    isWideUnit ? '102px' : theme.spacing.XL72};
  ${({ theme, flip }) =>
    flip
      ? `margin-left: ${theme.spacing.S8}`
      : `margin-right: ${theme.spacing.S8}`};
  ${({ flip }) => (flip ? 'order: 2;' : '')};
`;

export const Input = styled(TextField)`
  flex-grow: 1;
`;

function TextBoxWithUnit({
  unitName = 'unit',
  valueName = 'value',
  options,
  unitErrorMessage = 'Unit Required',
  valueErrorMessage = 'Value Required',
  placeholder,
  maxLength,
  flip = false,
  formatFunction,
  parseFunction,
  initialUnitValue,
  handleChangeCallback,
  handleBlurCallback,
  handleFocusCallback,
  willUseSubText = true,
  autoComplete,
  className,
  displayName,
  minimizeByDefault,
  selectDisabled = false,
  isWideUnit = false,
  optional,
  inputPattern,
  helpMessage,
}: ITextBoxWithUnit) {
  const [unitMeta, setUnitMeta] = useState<
    FieldRenderProps<any>['meta'] | undefined
  >(undefined);
  const [valueMeta, setValueMeta] = useState<
    FieldRenderProps<any>['meta'] | undefined
  >(undefined);

  return (
    <>
      {!minimizeByDefault && (
        <div className={className}>
          {displayName && <Label htmlFor={valueName}>{displayName}</Label>}
          <Container willUseSubText={willUseSubText}>
            <Field
              name={unitName}
              validate={optional ? undefined : required(unitErrorMessage)}
              initialValue={initialUnitValue}
            >
              {({ input, meta }) => (
                <SSelect
                  {...input}
                  options={options}
                  flip={flip}
                  hasError={
                    handleErrorMessage(meta) ??
                    (valueMeta && handleErrorMessage(valueMeta))
                  }
                  errorMessage={handleErrorMessage(meta)}
                  willUseSubText={false}
                  metaCallback={() => setUnitMeta(meta)}
                  isWideUnit={isWideUnit}
                  initialValue={initialUnitValue}
                  disabled={selectDisabled}
                />
              )}
            </Field>
            <Field
              name={valueName}
              format={(value) =>
                formatFunction ? formatFunction(value) : value
              }
              parse={(value) => (parseFunction ? parseFunction(value) : value)}
              validate={optional ? undefined : required(valueErrorMessage)}
            >
              {({ input, meta }) => (
                <Input
                  {...input}
                  // Allow input with commas
                  type="text"
                  pattern={inputPattern}
                  inputMode="numeric"
                  placeholder={placeholder}
                  maxLength={maxLength}
                  hasError={handleErrorMessage(meta)}
                  errorMessage={handleErrorMessage(meta)}
                  willUseSubText={false}
                  metaCallback={() => setValueMeta(meta)}
                  autoComplete={autoComplete ?? 'on'}
                  onBlur={() => {
                    handleBlurCallback && handleBlurCallback(input.value);
                    input.onBlur();
                  }}
                  onKeyPress={() =>
                    handleChangeCallback && handleChangeCallback(input.value)
                  }
                  onFocus={() => {
                    handleFocusCallback && handleFocusCallback(input.value);
                    input.onFocus();
                  }}
                />
              )}
            </Field>
          </Container>
          {willUseSubText && (
            <SubText>
              {unitMeta && handleErrorMessage(unitMeta) ? (
                <ErrorMessage text={handleErrorMessage(unitMeta)} />
              ) : valueMeta && handleErrorMessage(valueMeta) ? (
                <ErrorMessage text={handleErrorMessage(valueMeta)} />
              ) : helpMessage ? (
                <HelpText text={helpMessage} />
              ) : null}
            </SubText>
          )}
        </div>
      )}
    </>
  );
}

export { TextBoxWithUnit };
