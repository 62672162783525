/**
 * Required field
 */

export const required = (message?: string | null) => (value: any) =>
  value && value !== 'placeholder' ? undefined : message || 'Required';

/**
 * Is value valid email
 */
function emailIsValid(email: string) {
  return /\S+@\S+\.\S+/.test(email);
}

export const isEmail = (value: any) =>
  emailIsValid(value) ? undefined : 'Must be a valid email';

function phoneNumberIsValid(phone: string) {
  return /^(?=(.*\d){8,})[\d\+\s\-\(\)]{8,20}$/.test(phone);
}

export const isPhoneNumber = (value: any) =>
  phoneNumberIsValid(value) ? undefined : 'Please enter a valid phone number';

function authCodeIsValid(authCode: string) {
  return /^\d{6}$/.test(authCode);
}

export const isAuthCode = (value: any) =>
  authCodeIsValid(value) ? undefined : 'Incorrect code! Please try again';

// Other
export const composeValidators =
  (...validators: any) =>
  (value: any) =>
    validators.reduce(
      (error: any, validator: any) => error || validator(value),
      undefined,
    );
